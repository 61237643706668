<template>
  <div class="text-left ErrorNew" style="overflow: hidden">
    <div class="row">
      <div class="col-12 MainSection">
        <div>
          <!-- Logo -->
          <div class="px-5">
            <img
              src="/assets/img/new-website/LogoQuizell.svg"
              style="height: 40px; cursor: pointer"
              alt="Quizell"
              class="img-fluid"
              @click="$router.push('/')"
            />
          </div>

          <div
            class="d-flex flex-column flex-grow-1 align-items-center "
            style="height: 90vh"
          >
            <div class="">
              <LazyImage 
  :src="`https://images.quizell.com/website/404.png`" style="height:50vh;" :title="`Error 404`" :alt="`Error 404`" class="Img-Fluid"
 />
            </div>
            <div class="TitleDiv">
              <p class="title">Oops! There's something wrong.</p>
            </div>
            <div class="mt-3">
              <button
                class="btn ButtonHover Error-Btn px-5 py-3"
                @click="$router.push('/')"
              >
                Back to Home
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.ErrorNew {
  height: 100vh;
  background-image: url("/assets/img/new-website/home-main-hero2.svg");
  background-color: #ffffff;
  background-size: cover;
  background-repeat: no-repeat;
}
.ErrorNew .MainSection {
  padding: 2% 5%;
}
.MainSection .TitleDiv {
  padding: 48px;
}
.MainSection .title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 136%;

  text-align: center;

  color: #161e34;
}
.Error-Btn {
  background: #4d1b7e;
  border-radius: 100px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
}
.Error-Btn:hover {
  background: #ffc000;
  color: #ffffff;
}

@media (max-width: 560px) {
  .MainSection .title {
    font-weight: 500;
    font-size: 26px;
    line-height: 60px;
  }

  .MainSection .TitleDiv {
    padding: 28px;
  }
}
</style>
